var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticStyle:{"text-align":"center"}},[(_vm.dataItem.status === 6)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"id":'btn_' + _vm.dataItem.id,"fab":"","x-small":"","depressed":""},on:{"click":function($event){return _vm.pauseModel()}}},on),[_c('v-icon',{attrs:{"color":"#7D7D7D"}},[_vm._v("mdi-pause")])],1)]}}],null,false,733156664)},[_c('span',[_vm._v("暫停")])]):_vm._e(),(_vm.dataItem.status === 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"id":'btn_' + _vm.dataItem.id,"color":"#D1E7FF","fab":"","x-small":"","depressed":""},on:{"click":function($event){return _vm.continueModel()}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-play")])],1)]}}],null,false,2731457454)},[_c('span',[_vm._v("繼續")])]):_vm._e(),(_vm.dataItem.status === 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"id":'btn_' + _vm.dataItem.id,"color":"primary","fab":"","x-small":"","depressed":""},on:{"click":function($event){return _vm.retryModel()}}},on),[_c('v-icon',[_vm._v("mdi-replay")])],1)]}}],null,false,553236773)},[_c('span',[_vm._v("重試")])]):_vm._e(),(_vm.dataItem.status === 4)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":'btn_' + _vm.dataItem.id,"color":"primary","fab":"","x-small":"","depressed":""},on:{"click":function($event){return _vm.enableModel()}}},on),[_c('v-icon',[_vm._v("mdi-keyboard-return")])],1)]}}],null,false,3448847445)},[_c('span',[_vm._v("啟用")])]):_vm._e(),(_vm.dataItem.status !== 4)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":'btn_' + _vm.dataItem.id,"color":_vm.dataItem.status === 3 ? 'error' : '#FDECEC',"fab":"","x-small":"","depressed":""},on:{"click":function($event){return _vm.voidModel()}}},on),[_c('v-icon',{attrs:{"color":_vm.dataItem.status !== 3 ? 'error' : 'white'}},[_vm._v("mdi-delete-outline")])],1)]}}],null,false,3708415481)},[_c('span',[_vm._v("作廢")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }