var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 pa-0 mb-2",staticStyle:{"height":"calc(100% - 68px)"},attrs:{"flat":"","color":"transparent"}},[_vm._l((_vm.processingData),function(item){return _c('v-card',{key:item.id,staticClass:"ma-2 mt-0 ml-0 mr-0"},[_c('v-card-title',{staticClass:"pt-2 pb-2 pl-4"},[_c('v-container',{staticClass:"pa-0 ma-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0",staticStyle:{"min-height":"60px"},attrs:{"align":"center"}},[_c('v-icon',{staticClass:"pr-5 pl-1",attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-folder-sync")]),_c('v-col',{staticClass:"pa-0 ma-0 mr-8"},[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"subtitle-1 pa-0 ma-0"},[(item.groupName == null)?_c('span',[_vm._v("未分類群組")]):_vm._e(),_vm._v(_vm._s(item.groupName)+" "),_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(item.itemName)+" "),_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","outlined":"","small":"","color":"primary","disabled":"","id":"custom-disabled"}},[_vm._v("版本"+_vm._s(item.versionNumber))])],1),(item.processingAmount == undefined)?_c('v-card-text',{staticClass:"pa-0 ma-0"},[_vm._v("讀取中... ")]):_vm._e(),(item.processingAmount !== undefined)?_c('v-card-text',{staticClass:"pa-0 ma-0"},[_vm._v(_vm._s(item.processingAmount)+" 個處理中..."),(
                    item.processingAmount !== 0 &&
                      item.processingAmount !== undefined
                  )?_c('span',{staticClass:"error--text"}):_vm._e()]):_vm._e()],1)],1),_c('v-card',{attrs:{"width":"160","flat":"","color":"transparent"}},[_c('v-layout',{staticClass:"justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"fab":"","small":"","elevation":"0","color":"#FDECEC"},on:{"click":function($event){return _vm.voidDialogOpen(item)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("作廢")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"fab":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.routeToGroup(item)}}},on),[_c('v-icon',{staticStyle:{"color":"#656565"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("前往列表")])]),(!item.active)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.openItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v("檢視內容")])]):_vm._e(),(item.active)?_c('v-btn',{attrs:{"fab":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.closeItem()}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-expand-transition',[(item.active)?_c('v-card',{attrs:{"flat":"","loading":_vm.modelLoading}},[_c('v-divider'),_c('UploadModelsCard',{attrs:{"status":_vm.status,"modelList":_vm.modelList,"isPreprocessing":_vm.isPreprocessing},on:{"dataStateChange":_vm.dataStateChange,"loadAmount":_vm.loadAmount}})],1):_vm._e()],1)],1)}),(_vm.loading)?_c('div',{staticClass:"text-center pa-2"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.noData)?_c('v-card',{staticStyle:{"height":"100%"},attrs:{"flat":"","color":"transparent"}},[_c('EmptyCard')],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }