<template>
  <v-card
    flat
    style="height: calc(100% - 68px);"
    color="transparent"
    class="ma-0 pa-0 mb-2"
    ><v-card
      class="ma-2 mt-0 ml-0 mr-0"
      v-for="item in processingData"
      :key="item.id"
    >
      <v-card-title class="pt-2 pb-2 pl-4">
        <v-container fluid class="pa-0 ma-0" style="height:100%">
          <v-row class="pa-0 ma-0" align="center" style="min-height:60px">
            <v-icon x-large color="primary" class="pr-5 pl-1"
              >mdi-folder-sync</v-icon
            >
            <v-col class="pa-0 ma-0 mr-8">
              <v-card flat class="pa-0 ma-0" color="transparent"
                ><v-card-title class="subtitle-1 pa-0 ma-0">
                  <span v-if="item.groupName == null">未分類群組</span
                  >{{ item.groupName }}
                  <v-icon>mdi-chevron-right</v-icon>
                  {{ item.itemName }}
                  <v-btn
                    depressed
                    outlined
                    small
                    class="ml-3"
                    color="primary"
                    disabled
                    id="custom-disabled"
                    >版本{{ item.versionNumber }}</v-btn
                  >
                </v-card-title>
                <v-card-text
                  class="pa-0 ma-0"
                  v-if="item.processingAmount == undefined"
                  >讀取中...
                </v-card-text>
                <v-card-text
                  class="pa-0 ma-0"
                  v-if="item.processingAmount !== undefined"
                  >{{ item.processingAmount }} 個處理中...<span
                    class="error--text"
                    v-if="
                      item.processingAmount !== 0 &&
                        item.processingAmount !== undefined
                    "
                  ></span
                ></v-card-text>
              </v-card>
            </v-col>
            <v-card width="160" flat color="transparent">
              <v-layout class="justify-end">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      elevation="0"
                      class="mr-4"
                      @click="item.pause = true"
                      ><v-icon color="#7D7D7D">mdi-pause</v-icon></v-btn
                    >
                  </template>
                  <span>暫停</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      elevation="0"
                      class="mr-4"
                      color="#FDECEC"
                      @click="voidDialogOpen(item)"
                      ><v-icon color="error">mdi-delete-outline</v-icon></v-btn
                    >
                  </template>
                  <span>作廢</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      class="mr-4"
                      elevation="0"
                      @click="routeToGroup(item)"
                      ><v-icon style="color: #656565"
                        >mdi-open-in-new</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>前往列表</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!item.active">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      small
                      elevation="0"
                      @click="openItem(item)"
                      ><v-icon>mdi-chevron-down</v-icon></v-btn
                    >
                  </template>
                  <span>檢視內容</span>
                </v-tooltip>
                <v-btn
                  v-if="item.active"
                  fab
                  small
                  elevation="0"
                  @click="closeItem()"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn
                ></v-layout
              ></v-card
            ></v-row
          ></v-container
        >
      </v-card-title>
      <v-expand-transition>
        <v-card v-if="item.active" flat :loading="modelLoading"
          ><v-divider />
          <UploadModelsCard
            :status="status"
            :modelList="modelList"
            :isPreprocessing="isPreprocessing"
            @dataStateChange="dataStateChange"
            @loadAmount="loadAmount"
          />
        </v-card>
      </v-expand-transition>
    </v-card>
    <div v-if="loading" class="text-center pa-2">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card v-if="noData" style="height: 100%" flat color="transparent"
      ><EmptyCard /></v-card
  ></v-card>
</template>

<script>
import ApiConfig from '../../../plugins/ApiConfig';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import UploadModelsCard from './UploadModelsCard';
import EmptyCard from './EmptyCard';
export default {
  components: {
    UploadModelsCard,
    EmptyCard,
  },
  props: {
    processingData: {
      type: Array,
    },
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      status: 1,
      loading: true,
      item: {},
      noData: false,
      modelLoading: false,
      modelList: {},
      dataState: {
        page: 1,
        take: 20,
        skip: 0,
        sort: [{ field: 'name', dir: 'asc' }],
        filter: {
          filters: [
            {
              logic: 'or',
              filters: [
                {
                  field: 'status',
                  operator: 'neq',
                  value: 5,
                },
              ],
            },
          ],
          logic: 'or',
        },
        group: '',
      },
      isPreprocessing: true,
    };
  },
  watch: {
    processingData: {
      handler(val) {
        if (val.length == 0 && !this.loading) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    loadingStart() {
      this.loading = true;
    },
    loadingEnd() {
      this.loading = false;
    },
    loadAmount() {
      this.$emit('loadAmount');
      this.reloadStatusAmount();
    },
    async reloadStatusAmount() {
      var versionId = this.item.id;
      await this.$API.api.pc.productVersion
        .getModelsStatus(versionId, ApiConfig.getJsonConfig)
        .then((res) => {
          var processing = res.data.Processing;
          if (processing !== undefined) {
            this.item.processingAmount = processing;
          } else {
            this.item.processingAmount = 0;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    },
    voidDialogOpen(item) {
      this.$emit('voidDialogOpen', item);
    },
    openItem(item) {
      this.isPreprocessing = item.isPreprocessing;
      this.modelLoading = true;
      this.$emit('openItem', item);
      this.loadData(item);
    },
    closeItem() {
      this.$emit('closeItem');
    },
    async loadData(item) {
      this.item = item;
      var versionId = item.id;
      var dataState = this.dataState;
      const queryStr = toDataSourceRequestString(dataState);
      var urlencoded = new URLSearchParams(queryStr);
      var res = await this.$API.api.pc.productVersionModel.get(
        versionId,
        this.status,
        urlencoded,
        ApiConfig.getJsonConfig
      );
      this.modelList = res.data;
      this.modelLoading = false;
    },
    dataStateChange(event) {
      this.modelLoading = true;
      var dataState = event.data;
      this.dataState.take = dataState.take;
      this.dataState.skip = dataState.skip;
      this.dataState.sort = dataState.sort;
      // this.dataState.filter = dataState.filter;
      this.loadData(this.item);
    },
    /**開啟版本的模型列表*/
    routeToGroup(item) {
      var groupId = 'null';
      if (item.groupId !== null) {
        groupId = item.groupId;
      }
      const { href } = this.$router.resolve({
        name: 'ManagePage',
        params: { companyId: this.companyId },
        query: {
          group: groupId,
          item: item.itemId,
          version: item.id,
        },
      });
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-disabled.v-btn--disabled {
  color: #006ad8 !important;
}
</style>
