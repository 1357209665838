<template>
  <v-card flat color="#F8F8F8" class="pa-0">
    <localization-provider :language="$i18n.locale">
      <intl-provider :locale="$i18n.locale">
        <grid
          id="grid"
          ref="grid"
          :data-items="gridResults.gridData"
          :skip="gridResults.dataState.skip"
          :take="gridResults.dataState.take"
          :filter="gridResults.dataState.filter"
          :sort="gridResults.dataState.sort"
          :columns="columns"
          scrollable
          sortable
          reorderable
          column-menu
          pageable
          @datastatechange="dataStateChange"
          @pauseModel="pauseModel"
          @continueModel="continueModel"
          @retryModel="retryModel"
          @enableModel="enableModel"
          @voidModel="voidModel"
          style="height: 50vh"
        >
          <!-- :style="setKendoheight" -->
          <grid-no-records
            :style="{ minHeight: gridResults.loading ? '256px' : '128px' }"
          >
            <div v-if="gridResults.loading" class="k-loading-mask">
              <span class="k-loading-text"></span>
              <div class="k-loading-image" />
              <div class="k-loading-color" />
            </div>
            <div v-else>{{ $t('form.no_records') }}</div>
          </grid-no-records>
        </grid>
      </intl-provider>
    </localization-provider>
  </v-card>
</template>

<script>
import ApiConfig from '../../../plugins/ApiConfig';
import HandleStateCell from '../../GridCell/HandleStateCell';
import StateActionCell from '../../GridCell/StateActionCell';
import ModelNameDisplayCell from '../../GridCell/ModelNameDisplayCell';
export default {
  props: {
    status: {
      type: Number,
    },
    modelList: {
      type: Object,
    },
    isPreprocessing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      gridResults: {
        loading: true,
        list: [],
        total: 0,
        updatedData: [],
        gridData: {
          data: [],
          total: 0,
        },
        dataState: {
          page: 1,
          take: 20,
          skip: 0,
          sort: [{ field: 'name', dir: 'asc' }],
          filter: {
            filters: [
              {
                logic: 'or',
                filters: [
                  {
                    field: 'status',
                    operator: 'neq',
                    value: 5,
                  },
                ],
              },
            ],
            logic: 'or',
          },
          group: '',
        },
        pageable: {
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [10, 20, 50],
          previousNext: true,
        },
      },
      myColumns: [{ field: 'name', title: '型號', cell: ModelNameDisplayCell }],
    };
  },
  computed: {
    columns() {
      if (this.status === 5) {
        return [...this.myColumns];
      } else {
        return [
          {
            field: 'status',
            title: '狀態',
            width: '120px',
            minResizableWidth: 100,
            cell: HandleStateCell,
            attributes: {
              class: 'table-cell',
              style: 'text-align: center; font-size: 14px',
            },
          },
          ...this.myColumns,
          //其他動作欄位：檢視表單及參數、下載
          {
            width: '120px',
            minResizableWidth: 120,
            cell: StateActionCell,
          },
        ];
      }
    },
  },
  watch: {
    modelList: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.$data.gridResults.total = this.modelList.total;
      this.$data.gridResults.list = this.modelList.data;
      this.processModelData();
    },
    processModelData() {
      this.gridResults.gridData.data = this.gridResults.list;
      this.gridResults.gridData.total = this.gridResults.total;
      this.gridResults.loading = false;
    },
    dataStateChange(event) {
      this.$emit('dataStateChange', event);
      var dataState = event.data;
      this.gridResults.dataState.take = dataState.take;
      this.gridResults.dataState.skip = dataState.skip;
      this.gridResults.dataState.sort = dataState.sort;
      // this.gridResults.dataState.filter = dataState.filter;
      this.gridResults.dataState.filter = {
        filters: [
          {
            logic: 'or',
            filters: [
              {
                field: 'status',
                operator: 'eq',
                value: 5,
              },
            ],
          },
        ],
        logic: 'or',
      };
    },
    pauseModel(dataItem) {
      var modelId = dataItem.dataItem.id;
      var fromStatus = dataItem.dataItem.status;
      this.$API.api.pc.productModelStatus
        .changeModelStatus(modelId, fromStatus, 2, ApiConfig.getJsonConfig)
        .then(() => {
          this.$emit('loadAmount');
          dataItem.dataItem.status = 2;
          let msg = '已暫停';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {});
    },
    continueModel(dataItem) {
      console.log('continueModel', dataItem);
      var modelId = dataItem.dataItem.id;
      this.$API.api.pc.productModel
        .resumeProcess(modelId, ApiConfig.getJsonConfig)
        .then(() => {
          this.$emit('loadAmount');
          dataItem.dataItem.status = 6;
          let msg = '繼續處理成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {});
    },
    retryModel(dataItem) {
      console.log('retryModel', dataItem);
      var modelId = dataItem.dataItem.id;
      this.$API.api.pc.productModel
        .retryProcess(modelId, ApiConfig.getJsonConfig)
        .then(() => {
          this.$emit('loadAmount');
          dataItem.dataItem.status = 6;
          let msg = '重試成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {});
    },
    enableModel(dataItem) {
      var modelId = dataItem.dataItem.id;
      var fromStatus = dataItem.dataItem.status;
      this.$API.api.pc.productModelStatus
        .changeModelStatus(modelId, fromStatus, 0, ApiConfig.getJsonConfig)
        .then(() => {
          this.$emit('loadAmount');
          dataItem.dataItem.status = 0;
          let msg = '啟用成功';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {});
    },
    voidModel(dataItem) {
      var modelId = dataItem.dataItem.id;
      var fromStatus = dataItem.dataItem.status;
      this.$API.api.pc.productModelStatus
        .changeModelStatus(modelId, fromStatus, 4, ApiConfig.getJsonConfig)
        .then(() => {
          this.$emit('loadAmount');
          dataItem.dataItem.status = 4;
          let msg = '已作廢';
          this.$store.commit('notification/SET_ALERT', {
            type: 'success',
            message: msg,
            value: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
#custom-disabled.v-btn--disabled {
  background-color: #ffffff !important;
}
</style>
