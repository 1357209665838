<template>
  <v-card flat>
    <v-alert text outlined type="error" class="mb-2 pa-3">
      剩餘點數 <strong>{{ balance }} 點</strong>
    </v-alert></v-card
  >
</template>

<script>
export default {
  props: {
    balance: {
      type: Number,
    },
  },
};
</script>
