<template>
  <v-card
    flat
    style="height: calc(100% - 68px);"
    color="transparent"
    class="ma-0 pa-3 mb-2"
    ><PendingMode
      ref="pendingMode"
      v-if="mode == 'pending'"
      :pendingData="pendingData"
      :companyId="companyId"
      :balance="balance"
      @openItem="openItem"
      @closeItem="closeItem"
      @pendingDialogOpen="pendingDialogOpen"
      @voidDialogOpen="voidDialogOpen"
      @loadAmount="loadAmount"
    />
    <QueuingMode
      ref="queuingMode"
      v-else-if="mode == 'queuing'"
      :queuingData="queuingData"
      :companyId="companyId"
      @openItem="openItem"
      @closeItem="closeItem"
      @pauseDialogOpen="pauseDialogOpen"
      @voidDialogOpen="voidDialogOpen"
      @loadAmount="loadAmount"
    />
    <ProcessingMode
      ref="processingMode"
      v-else-if="mode == 'processing'"
      :processingData="processingData"
      :companyId="companyId"
      @openItem="openItem"
      @closeItem="closeItem"
      @voidDialogOpen="voidDialogOpen"
      @loadAmount="loadAmount"
    />
    <PausedMode
      ref="pausedMode"
      v-else-if="mode == 'paused'"
      :pausedData="pausedData"
      :companyId="companyId"
      :balance="balance"
      @openItem="openItem"
      @closeItem="closeItem"
      @continueDialogOpen="continueDialogOpen"
      @voidDialogOpen="voidDialogOpen"
      @loadAmount="loadAmount"
    />
    <FailedMode
      ref="failedMode"
      v-else-if="mode == 'failed'"
      :failedData="failedData"
      :companyId="companyId"
      :balance="balance"
      @openItem="openItem"
      @closeItem="closeItem"
      @retryDialogOpen="retryDialogOpen"
      @voidDialogOpen="voidDialogOpen"
      @loadAmount="loadAmount"
    />
    <VoidMode
      ref="voidMode"
      v-else-if="mode == 'void'"
      :voidData="voidData"
      :companyId="companyId"
      @openItem="openItem"
      @closeItem="closeItem"
      @enableDialogOpen="enableDialogOpen"
      @loadAmount="loadAmount"
    />
    <FinishMode
      ref="finishMode"
      v-else
      :finishData="finishData"
      :companyId="companyId"
      @openItem="openItem"
      @closeItem="closeItem"
      @voidDialogOpen="voidDialogOpen"
    />
  </v-card>
</template>

<script>
import PendingMode from './PendingMode';
import QueuingMode from './QueuingMode';
import ProcessingMode from './ProcessingMode';
import PausedMode from './PausedMode';
import FailedMode from './FailedMode';
import VoidMode from './VoidMode';
import FinishMode from './FinishMode';
export default {
  components: {
    PendingMode,
    QueuingMode,
    ProcessingMode,
    PausedMode,
    FailedMode,
    VoidMode,
    FinishMode,
  },
  props: {
    companyId: {
      type: String,
    },
    mode: {
      type: String,
    },
    balance: {
      type: Number,
    },
    pendingData: {
      type: Array,
    },
    queuingData: {
      type: Array,
    },
    processingData: {
      type: Array,
    },
    pausedData: {
      type: Array,
    },
    failedData: {
      type: Array,
    },
    voidData: {
      type: Array,
    },
    finishData: {
      type: Array,
    },
  },
  methods: {
    loadingStart(e) {
      switch (e) {
        case 0: //待提交
          this.$refs.pendingMode.loadingStart();
          break;
        case 6: //待處理
          this.$refs.queuingMode.loadingStart();
          break;
        case 1: //處理中
          this.$refs.processingMode.loadingStart();
          break;
        case 2: //已暫停
          this.$refs.pausedMode.loadingStart();
          break;
        case 3: //處理失敗
          this.$refs.failedMode.loadingStart();
          break;
        case 4: //作廢
          this.$refs.voidMode.loadingStart();
          break;
        case 5: //作廢
          this.$refs.finishMode.loadingStart();
          break;
      }
    },
    loadingEnd(e) {
      switch (e) {
        case 0: //待提交
          this.$refs.pendingMode.loadingEnd();
          break;
        case 6: //待處理
          this.$refs.queuingMode.loadingEnd();
          break;
        case 1: //處理中
          this.$refs.processingMode.loadingEnd();
          break;
        case 2: //已暫停
          this.$refs.pausedMode.loadingEnd();
          break;
        case 3: //處理失敗
          this.$refs.failedMode.loadingEnd();
          break;
        case 4: //作廢
          this.$refs.voidMode.loadingEnd();
          break;
        case 5: //作廢
          this.$refs.finishMode.loadingEnd();
          break;
      }
    },
    pendingDialogOpen(item) {
      this.$emit('pendingDialogOpen', item);
    },
    pauseDialogOpen(item) {
      this.$emit('pauseDialogOpen', item);
    },
    continueDialogOpen(item) {
      this.$emit('continueDialogOpen', item);
    },
    retryDialogOpen(item) {
      this.$emit('retryDialogOpen', item);
    },
    enableDialogOpen(item) {
      this.$emit('enableDialogOpen', item);
    },
    voidDialogOpen(item) {
      this.$emit('voidDialogOpen', item);
    },
    openItem(item) {
      this.$emit('openItem', item);
    },
    closeItem() {
      this.$emit('closeItem');
    },
    loadAmount() {
      this.$emit('loadAmount');
    },
  },
};
</script>
