<template>
  <v-card flat style="height:85vh;"
    ><UploadState :companyId="companyId" :status="status"
  /></v-card>
</template>

<script>
import { mapState } from 'vuex';
import UploadState from '../../components/UploadState/Main';
export default {
  components: {
    UploadState,
  },
  data() {
    return {
      // companyId: this.$route.params.companyId,
      status: this.$route.query.status,
    };
  },
  computed: {
    ...mapState('company', {
      companyId: (state) => state.company.id,
    }),
  },
  watch: {
    companyId: {
      handler(val) {
        if (val === undefined || val === null || val === '') {
          this.companyId = this.$route.params.companyId;
        }
      },
      deep: true,
    },
  },
};
</script>
