<template>
  <td style="text-align: center;">
    <v-tooltip bottom v-if="dataItem.status === 6">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          fab
          class="mr-2"
          x-small
          depressed
          @click="pauseModel()"
          ><v-icon color="#7D7D7D">mdi-pause</v-icon></v-btn
        >
      </template>

      <span>暫停</span>
    </v-tooltip>
    <v-tooltip bottom v-if="dataItem.status === 2">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="#D1E7FF"
          fab
          class="mr-2"
          x-small
          depressed
          @click="continueModel()"
          ><v-icon color="primary">mdi-play</v-icon></v-btn
        >
      </template>

      <span>繼續</span>
    </v-tooltip>
    <v-tooltip bottom v-if="dataItem.status === 3">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          fab
          class="mr-2"
          x-small
          depressed
          @click="retryModel()"
          ><v-icon>mdi-replay</v-icon></v-btn
        >
      </template>

      <span>重試</span>
    </v-tooltip>
    <v-tooltip bottom v-if="dataItem.status === 4">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          color="primary"
          fab
          x-small
          depressed
          @click="enableModel()"
          ><v-icon>mdi-keyboard-return</v-icon></v-btn
        >
      </template>

      <span>啟用</span>
    </v-tooltip>
    <v-tooltip bottom v-if="dataItem.status !== 4">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="'btn_' + dataItem.id"
          :color="dataItem.status === 3 ? 'error' : '#FDECEC'"
          fab
          x-small
          depressed
          @click="voidModel()"
          ><v-icon :color="dataItem.status !== 3 ? 'error' : 'white'"
            >mdi-delete-outline</v-icon
          ></v-btn
        >
      </template>

      <span>作廢</span>
    </v-tooltip>
  </td>
</template>

<script>
export default {
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data() {
    return {
      downloadType: '',
    };
  },
  methods: {
    pauseModel() {
      this.$emit('pauseModel', { dataItem: this.dataItem });
    },
    continueModel() {
      this.$emit('continueModel', { dataItem: this.dataItem });
    },
    retryModel() {
      this.$emit('retryModel', { dataItem: this.dataItem });
    },
    enableModel() {
      this.$emit('enableModel', { dataItem: this.dataItem });
    },
    voidModel() {
      this.$emit('voidModel', { dataItem: this.dataItem });
    },
  },
};
</script>

<style></style>
