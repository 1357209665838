<template>
  <sb-ui-fullScreen
    ref="full-screen"
    :z-index="200"
    height="100vh"
    @toggle="onToggle"
  >
    <v-card
      v-if="!forbidden"
      outlined
      :style="isFullScreen ? 'height: 100vh;' : 'height: 85vh;'"
    >
      <v-card-title style=" height: 68px;">
        <v-icon class="pr-3"> mdi-cloud-sync-outline</v-icon>模型轉檔管理

        <v-icon>mdi-chevron-right</v-icon
        >{{ processingCount }} 個規格品處理中...
        <v-spacer />

        <v-text-field
          v-model="search.text"
          class="table-search ma-0 pa-0 mr-3"
          append-icon="mdi-magnify"
          :label="$t('form.general.search')"
          single-line
          hide-details
          clearable
          @keyup.13="searchEvent"
          @click:append="searchEvent"
        ></v-text-field>
        <v-divider class="mx-3" vertical />
        <v-tooltip bottom>
          <template #activator="{on}">
            <v-btn v-on="on" icon @click="toggleFullscreen">
              <v-icon v-if="!isFullScreen">mdi-fullscreen</v-icon>
              <v-icon v-else>mdi-fullscreen-exit</v-icon>
            </v-btn>
          </template>
          <span>{{ isFullScreen ? '退出全螢幕' : '全螢幕' }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider />
      <v-card flat style="height:calc(100% - 69px);">
        <v-card-text class="pa-0 ma-0"
          ><StatusTab
            ref="statusTab"
            :statusData="statusData"
            :statusFromRoute="status"
            :loading="loading"
            @click="tabClick($event)"
          />
        </v-card-text>
        <v-card
          v-on:scroll.native="handleScroll"
          id="listCard"
          flat
          :style="
            mode == 'pending'
              ? 'height:calc(100% - 48px);overflow-y: scroll; background-color:#F3F3F3;'
              : 'height:calc(100% - 48px);overflow-y: scroll; background-color:#F3F3F3;'
          "
          :loading="loading"
        >
          <UploadLists
            ref="uploadList"
            :companyId="companyId"
            :mode="mode"
            :pendingData="pendingData"
            :queuingData="queuingData"
            :processingData="processingData"
            :pausedData="pausedData"
            :failedData="failedData"
            :voidData="voidData"
            :finishData="finishData"
            :balance="balance"
            @pendingDialogOpen="pendingDialogOpen"
            @pauseDialogOpen="pauseDialogOpen"
            @continueDialogOpen="continueDialogOpen"
            @retryDialogOpen="retryDialogOpen"
            @enableDialogOpen="enableDialogOpen"
            @voidDialogOpen="voidDialogOpen"
            @loadAmount="loadAmount"
            @openItem="openItem"
            @closeItem="closeItem"
          />
        </v-card>
      </v-card>
      <v-dialog v-model="pendingDialog.value" max-width="550"
        ><PendingDialog
          :companyId="companyId"
          :pendingItem="pendingDialog.pendingItem"
          @pendingDialogClose="pendingDialog.value = false"
          @pendingConfirm="pendingConfirm"/></v-dialog
      ><v-dialog v-model="pauseDialog.value" max-width="550"
        ><PauseDialog
          :pauseItem="pauseDialog.pauseItem"
          @pauseDialogClose="pauseDialog.value = false"
          @pauseConfirm="pauseConfirm"
      /></v-dialog>
      <v-dialog v-model="continueDialog.value" max-width="550"
        ><ContinueDialog
          :companyId="companyId"
          :continueItem="continueDialog.continueItem"
          @continueDialogClose="continueDialog.value = false"
          @continueConfirm="continueConfirm"
      /></v-dialog>
      <v-dialog v-model="retryDialog.value" max-width="550"
        ><RetryDialog
          :companyId="companyId"
          :retryItem="retryDialog.retryItem"
          @retryDialogClose="retryDialog.value = false"
          @retryConfirm="retryConfirm"
      /></v-dialog>
      <v-dialog v-model="enableDialog.value" max-width="550"
        ><EnableDialog
          :enableItem="enableDialog.enableItem"
          @enableDialogClose="enableDialog.value = false"
          @enableConfirm="enableConfirm"
      /></v-dialog>
      <v-dialog v-model="voidDialog.value" max-width="550"
        ><VoidDialog
          :voidItem="voidDialog.voidItem"
          @voidDialogClose="voidDialog.value = false"
          @voidConfirm="voidConfirm"
      /></v-dialog>
      <!-- <LoadingDialog :value="loading" /> -->
    </v-card>
    <v-card flat color="transparent" v-if="forbidden"><Forbidden403 /></v-card>
  </sb-ui-fullScreen>
</template>

<script>
import Vue from 'vue';
import ApiConfig from '../../plugins/ApiConfig';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';
import UploadLists from './UploadLists/Main';
import StatusTab from './StatusTab';
import PendingDialog from '../Dialog/PendingDialog';
import PauseDialog from '../Dialog/PauseDialog';
import ContinueDialog from '../Dialog/ContinueDialog';
import RetryDialog from '../Dialog/RetryDialog';
import EnableDialog from '../Dialog/EnableDialog';
import VoidDialog from '../Dialog/VoidDialog';
import Forbidden403 from '../SystemPage/Forbidden403';

export default {
  components: {
    UploadLists,
    StatusTab,
    PendingDialog,
    PauseDialog,
    ContinueDialog,
    RetryDialog,
    EnableDialog,
    VoidDialog,
    Forbidden403,
  },
  props: {
    companyId: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      isFullScreen: false,
      mode: 'pending',
      tab: 0,
      loading: true,
      page: 1,
      take: 30,
      total: 0,
      balance: null,
      search: {
        text: '',
      },
      searching: false,
      voidAll: false,

      statusData: {},
      pendingData: [],
      queuingData: [],
      processingData: [],
      pausedData: [],
      failedData: [],
      voidData: [],
      finishData: [],

      dataState: {
        take: 30,
        skip: 0,
        filter: null,
      },

      pendingDialog: {
        value: false,
        pendingItem: {},
      },
      pauseDialog: {
        value: false,
        pauseItem: {},
      },
      continueDialog: {
        value: false,
        continueItem: {},
      },
      retryDialog: {
        value: false,
        retryItem: {},
      },
      enableDialog: {
        value: false,
        enableItem: {},
      },
      voidDialog: {
        value: false,
        voidItem: {},
      },

      processingCount: 0,
      pendingCount: 0,
      forbidden: false,
    };
  },
  created() {
    let self = this;
    window.addEventListener('scroll', this.handleScroll);
    /**signalR回傳時，需要重新load狀態數量 loadAmount()
     * 同時取得version Detail 傳送通知 getVersionDetails()
     */
    this.$signalr.pc.hubMethods['ProductVersionStatusChanged'] = function(
      versionId,
      from,
      to
    ) {
      console.log('siganl R', versionId, from, to);
      self.getVersionDetails(versionId);
      self.loadAmount();
      self.$refs.uploadList.loadingStart(this.tab);
      self.tabClick(this.tab);
      console.log('from', from, 'to', to);
    };
    this.$signalr.pc.connect();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    this.$signalr.pc.disconnect();
  },
  watch: {
    companyId: {
      handler() {
        this.dataInit();
        if (this.status !== undefined) {
          this.tabClick(parseInt(this.status));
        } else {
          this.loadLists(this.tab);
        }
        this.loadAmount();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.status !== undefined) {
      this.tabClick(parseInt(this.status));
    } else {
      this.loadLists(this.tab);
    }
    this.loadAmount();
  },
  methods: {
    dataInit() {
      this.statusData = {};
      this.pendingData = [];
      this.queuingData = [];
      this.processingData = [];
      this.pausedData = [];
      this.failedData = [];
      this.voidData = [];
      this.finishData = [];
    },
    /**根據signalR回傳的versionId讀取version資訊 */
    getVersionDetails(versionId) {
      this.$API.api.pc.productVersion
        .get(versionId, ApiConfig.getJsonConfig)
        .then((res) => {
          if (res.data.status == 5) {
            this.pushSuccessNotify(res.data);
          } else if (res.data.status == 3) {
            this.pushFailedNotify(res.data);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    },
    pushSuccessNotify(version) {
      var itemName = version.itemName;
      var versionNum = version.versionNumber;
      this.$notify({
        title: '模型轉檔成功',
        type: 'success',
        text: itemName + ' 版本' + versionNum + ' 完成轉檔',
      });
    },
    pushFailedNotify(version) {
      var itemName = version.itemName;
      var versionNum = version.versionNumber;
      this.$notify({
        title: '模型轉檔失敗',
        type: 'error',
        text: itemName + ' 版本' + versionNum + ' 轉檔失敗',
      });
    },
    /**讀取剩餘額度 */
    async loadBalance() {
      var companyId = this.companyId;
      var start = null;
      var end = null;
      var res = await this.$API.api.pc.companyProcessingUsage.getBalance(
        companyId,
        start,
        end,
        ApiConfig.getJsonConfig
      );
      this.balance = res.data;
    },
    /** scroll paging */
    handleScroll() {
      // if (!this.searching) {
      var scrollHeight = document.getElementById('listCard').scrollHeight;
      var cardHeight = document.getElementById('listCard').clientHeight;
      var scrollMove = document.getElementById('listCard').scrollTop;
      var scrollToBottom = scrollHeight < cardHeight + scrollMove + 84 * 15;

      if (scrollToBottom && !this.loading) {
        this.page += 1;
        if ((this.page - 1) * this.take < this.total) {
          this.loadLists(this.tab);
        } else {
          this.$refs.uploadList.loadingEnd(this.tab);
        }
        // }
      }
    },
    tabClick(e) {
      if (e !== this.tab) {
        this.addStatusToLocation(e);
        this.tab = e;
        this.page = 1;
        this.total = 0;
        switch (e) {
          case 0: //待提交
            this.mode = 'pending';
            this.pendingData = [];
            this.loadLists(0);
            break;
          case 6: //待處理
            this.mode = 'queuing';
            this.queuingData = [];
            this.loadLists(6);
            break;
          case 1: //處理中
            this.mode = 'processing';
            this.processingData = [];
            this.loadLists(1);
            break;
          case 2: //已暫停
            this.mode = 'paused';
            this.pausedData = [];
            this.loadLists(2);
            break;
          case 3: //處理失敗
            this.mode = 'failed';
            this.failedData = [];
            this.loadLists(3);
            break;
          case 4: //作廢
            this.mode = 'void';
            this.voidData = [];
            this.loadLists(4);
            break;
          case 5: //已完成
            this.mode = 'finish';
            this.finishData = [];
            this.loadLists(5);
            break;
        }
      }
    },
    async loadAmount() {
      var res = await this.$API.api.pc.companyProductModel.get(
        this.companyId,
        ApiConfig.getJsonConfig
      );
      this.statusData = res.data;
      if (this.statusData['Processing'] !== undefined) {
        this.processingCount = this.statusData['Processing'];
      }
      if (this.statusData['Pending'] !== undefined) {
        this.pendingCount = this.statusData['Pending'];
      } else {
        this.pendingCount = 0;
      }
      this.loadBalance();
    },
    loadLists(status) {
      this.loading = true;
      return new Promise((resolve) => {
        this.dataState.skip = (this.page - 1) * this.take;
        var dataState = this.dataState;
        const queryStr = toDataSourceRequestString(dataState);
        var urlencoded = new URLSearchParams(queryStr);
        this.$API.api.pc.companyProductVersion
          .get(
            status,
            this.companyId,
            true,
            urlencoded,
            ApiConfig.getJsonConfig
          )
          .then((res) => {
            // this.catLists(status, res.data);
            this.addAction(status, res.data);
            this.total = res.data.total;
            if (this.take >= this.total) {
              this.$refs.uploadList.loadingEnd(this.tab);
            }
            // console.log("res", res);
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status === 403) {
              this.forbidden = true;
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },
    addAction(status, data) {
      var dataArr = cloneDeep(data);
      for (let i = 0; i < dataArr.data; i++) {
        Vue.set(dataArr.data[i], 'active', false);
      }
      this.catLists(status, dataArr);
    },
    catLists(status, data) {
      switch (status) {
        case 0: //待提交
          var origin = cloneDeep(this.pendingData);
          var catArr = origin.concat(data.data);
          this.pendingData = catArr;
          this.loadStatusAmount(this.pendingData);
          this.loading = false;
          break;

        case 6: //待處理
          var originQue = cloneDeep(this.queuingData);
          var catArrQue = originQue.concat(data.data);
          this.queuingData = catArrQue;
          this.loadStatusAmount(this.queuingData);
          this.loading = false;
          break;

        case 1: //處理中
          var originPro = cloneDeep(this.processingData);
          var catArrPro = originPro.concat(data.data);
          this.processingData = catArrPro;
          this.loadStatusAmount(this.processingData);
          this.loading = false;
          break;

        case 2: //已暫停
          var originPau = cloneDeep(this.pausedData);
          var catArrPau = originPau.concat(data.data);
          this.pausedData = catArrPau;
          this.loadStatusAmount(this.pausedData);
          this.loading = false;
          break;

        case 3: //處理失敗
          var originFail = cloneDeep(this.failedData);
          var catArrFail = originFail.concat(data.data);
          this.failedData = catArrFail;
          this.loadStatusAmount(this.failedData);
          this.loading = false;
          break;

        case 4: //作廢
          var originVoid = cloneDeep(this.voidData);
          var catArrVoid = originVoid.concat(data.data);
          this.voidData = catArrVoid;
          this.loadStatusAmount(this.voidData);
          this.loading = false;
          break;

        case 5: //已完成
          var originFinish = cloneDeep(this.finishData);
          var catArrFinish = originFinish.concat(data.data);
          this.finishData = catArrFinish;
          this.loadStatusAmount(this.finishData);
          this.loading = false;
          break;
      }
    },
    /**獲得版本個狀態的規格品數量 */
    async loadStatusAmount(dataList) {
      for (let i = 0; i < dataList.length; i++) {
        var version = dataList[i];
        var versionId = version.id;
        await this.$API.api.pc.productVersion
          .getModelsStatus(versionId, ApiConfig.getJsonConfig)
          .then((res) => {
            this.stickStatus(version, res.data);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {});
      }
      // console.log("dataList", dataList);
    },
    /**對應狀態數量 */
    stickStatus(version, data) {
      switch (this.tab) {
        case 0: //待提交
          var pending = 0;
          if (data.Pending) {
            pending = data.Pending;
          }
          Vue.set(version, 'pendingAmount', pending);
          break;
        case 6: //待處理
          var queuing = 0;
          if (data.Queuing) {
            queuing = data.Queuing;
          }
          Vue.set(version, 'queuingAmount', queuing);
          break;
        case 1: //處理中
          var processing = 0;
          var failed = 0;
          if (data.Processing) {
            processing = data.Processing;
          }
          if (data.Failed) {
            failed = data.Failed;
          }
          Vue.set(version, 'processingAmount', processing);
          Vue.set(version, 'failedAmount', failed);
          break;
        case 2: //已暫停
          break;
        case 3: //處理失敗
          var failedAmount = 0;
          if (data.Failed) {
            failedAmount = data.Failed;
          }
          Vue.set(version, 'failedAmount', failedAmount);
          break;
        case 4: //作廢
          break;
        case 5: //已完成
          var finish = 0;
          if (data.Finished) {
            finish = data.Finished;
          }
          Vue.set(version, 'finishAmount', finish);
          break;
      }
    },
    /**展開元件版本內容 */
    openItem(item) {
      switch (this.tab) {
        case 0: //待提交
          for (let i = 0; i < this.pendingData.length; i++) {
            Vue.set(this.pendingData[i], 'active', false);
            if (item.id == this.pendingData[i].id) {
              Vue.set(this.pendingData[i], 'active', true);
            }
          }
          break;
        case 6: //待處理
          for (let i = 0; i < this.queuingData.length; i++) {
            Vue.set(this.queuingData[i], 'active', false);
            if (item.id == this.queuingData[i].id) {
              Vue.set(this.queuingData[i], 'active', true);
            }
          }
          break;
        case 1: //處理中
          for (let i = 0; i < this.processingData.length; i++) {
            Vue.set(this.processingData[i], 'active', false);
            if (item.id == this.processingData[i].id) {
              Vue.set(this.processingData[i], 'active', true);
            }
          }
          break;
        case 2: //已暫停
          for (let i = 0; i < this.pausedData.length; i++) {
            Vue.set(this.pausedData[i], 'active', false);
            if (item.id == this.pausedData[i].id) {
              Vue.set(this.pausedData[i], 'active', true);
            }
          }
          break;
        case 3: //處理失敗
          for (let i = 0; i < this.failedData.length; i++) {
            Vue.set(this.failedData[i], 'active', false);
            if (item.id == this.failedData[i].id) {
              Vue.set(this.failedData[i], 'active', true);
            }
          }
          break;
        case 4: //作廢
          for (let i = 0; i < this.voidData.length; i++) {
            Vue.set(this.voidData[i], 'active', false);
            if (item.id == this.voidData[i].id) {
              Vue.set(this.voidData[i], 'active', true);
            }
          }
          break;
        case 5: //已完成
          for (let i = 0; i < this.finishData.length; i++) {
            Vue.set(this.finishData[i], 'active', false);
            if (item.id == this.finishData[i].id) {
              Vue.set(this.finishData[i], 'active', true);
            }
          }
          break;
      }
    },
    /**關閉元件版本內容 */
    closeItem() {
      switch (this.tab) {
        case 0: //待提交
          for (let i = 0; i < this.pendingData.length; i++) {
            Vue.set(this.pendingData[i], 'active', false);
          }
          break;
        case 6: //待提交
          for (let i = 0; i < this.queuingData.length; i++) {
            Vue.set(this.queuingData[i], 'active', false);
          }
          break;
        case 1: //處理中
          for (let i = 0; i < this.processingData.length; i++) {
            Vue.set(this.processingData[i], 'active', false);
          }
          break;
        case 2: //已暫停
          for (let i = 0; i < this.pausedData.length; i++) {
            Vue.set(this.pausedData[i], 'active', false);
          }
          break;
        case 3: //處理失敗
          for (let i = 0; i < this.failedData.length; i++) {
            Vue.set(this.failedData[i], 'active', false);
          }
          break;
        case 4: //作廢
          for (let i = 0; i < this.voidData.length; i++) {
            Vue.set(this.voidData[i], 'active', false);
          }
          break;
        case 5: //已完成
          for (let i = 0; i < this.finishData.length; i++) {
            Vue.set(this.finishData[i], 'active', false);
          }
          break;
      }
    },
    /**搜尋 */
    searchEvent() {
      this.$refs.uploadList.loadingStart(this.tab);
      this.page = 1;
      this.searching = true;
      let filterString = '';
      if (this.search.text !== null) {
        filterString = this.search.text.trim();
      } else {
        filterString = this.search.text;
      }

      if (filterString == '' || filterString == null) {
        this.dataState.filter = {};
      } else {
        // this.$refs.uploadList.loadingEnd(this.tab);
        this.dataState.filter = {
          filters: [
            {
              logic: 'or',
              filters: [
                {
                  field: 'itemName',
                  operator: 'contains',
                  value: filterString,
                },
                {
                  field: 'groupName',
                  operator: 'contains',
                  value: filterString,
                },
              ],
            },
          ],
          logic: 'or',
        };
      }
      // console.log("this.dataState", this.dataState);
      this.reload();
      this.loadLists(this.tab);
    },
    reload() {
      this.pendingData = [];
      this.queuingData = [];
      this.processingData = [];
      this.pausedData = [];
      this.failedData = [];
      this.voidData = [];
      this.finishData = [];
    },
    /**提交功能 */
    pendingDialogOpen(item) {
      this.pendingDialog.value = true;
      this.pendingDialog.pendingItem = item;
    },
    pendingDialogClose() {
      this.pendingDialog.value = false;
    },
    pendingConfirm() {
      this.$refs.uploadList.loadingStart(this.tab);
      this.loading = true;
      this.reload();
      this.page = 1;
      this.pendingDialog.value = false;
      var versionId = this.pendingDialog.pendingItem.id;
      this.$API.api.pc.productVersion
        .post(versionId, ApiConfig.getJsonConfig)
        .then(() => {
          this.loadAmount();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.loadLists(this.tab);
        });
    },
    /**作廢功能 */
    voidDialogOpen(item) {
      this.voidDialog.value = true;
      this.voidDialog.voidItem = item;
    },
    voidConfirm() {
      this.$refs.uploadList.loadingStart(this.tab);
      this.loading = true;
      this.reload();
      this.page = 1;
      this.voidDialog.value = false;
      var versionId = this.voidDialog.voidItem.id;
      var fromStatus = this.tab;
      this.$API.api.pc.productVersionStatus
        .changeStatus(versionId, fromStatus, 4, ApiConfig.getJsonConfig)
        .then(() => {
          this.loadAmount();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.loadLists(this.tab);
        });
    },
    /**暫停功能 */
    pauseDialogOpen(item) {
      this.pauseDialog.value = true;
      this.pauseDialog.pauseItem = item;
    },
    pauseConfirm() {
      this.$refs.uploadList.loadingStart(this.tab);
      this.loading = true;
      this.reload();
      this.page = 1;
      this.pauseDialog.value = false;
      var versionId = this.pauseDialog.pauseItem.id;
      var fromStatus = this.tab;
      this.$API.api.pc.productVersionStatus
        .changeStatus(versionId, fromStatus, 2, ApiConfig.getJsonConfig)
        .then(() => {
          this.loadAmount();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.loadLists(this.tab);
        });
    },
    /**繼續功能 */
    async continueDialogOpen(item) {
      this.continueDialog.value = true;
      this.continueDialog.continueItem = item;
      var versionId = item.id;
      var res = await this.$API.api.pc.productVersion.getContinueAmount(
        versionId,
        ApiConfig.getJsonConfig
      );
      Vue.set(this.continueDialog.continueItem, 'continueModelCount', res.data);
    },
    continueConfirm() {
      this.$refs.uploadList.loadingStart(this.tab);
      this.loading = true;
      this.reload();
      this.page = 1;
      this.continueDialog.value = false;
      var versionId = this.continueDialog.continueItem.id;
      this.$API.api.pc.productVersion
        .postContinue(versionId, ApiConfig.getJsonConfig)
        .then(() => {
          this.loadAmount();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.loadLists(this.tab);
        });
    },
    /**重試功能 */
    retryDialogOpen(item) {
      this.retryDialog.value = true;
      this.retryDialog.retryItem = item;
    },
    retryConfirm() {
      this.$refs.uploadList.loadingStart(this.tab);
      this.loading = true;
      this.reload();
      this.page = 1;
      this.retryDialog.value = false;
      var versionId = this.retryDialog.retryItem.id;
      this.$API.api.pc.productVersion
        .retry(versionId, ApiConfig.getJsonConfig)
        .then(() => {
          this.loadAmount();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.loadLists(this.tab);
        });
    },
    /**啟用功能 */
    enableDialogOpen(item) {
      this.enableDialog.value = true;
      this.enableDialog.enableItem = item;
    },
    enableConfirm() {
      this.$refs.uploadList.loadingStart(this.tab);
      this.loading = true;
      this.reload();
      this.page = 1;
      this.enableDialog.value = false;
      var versionId = this.enableDialog.enableItem.id;
      var fromStatus = this.tab;
      this.$API.api.pc.productVersionStatus
        .changeStatus(versionId, fromStatus, 0, ApiConfig.getJsonConfig)
        .then(() => {
          this.loadAmount();
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('notification/SET_DIALOG', {
            title: 'Error',
            message: error,
            value: true,
          });
        })
        .finally(() => {
          this.loadLists(this.tab);
        });
    },
    onToggle(value) {
      this.isFullScreen = value;
    },
    toggleFullscreen() {
      this.$refs['full-screen'].toggle();
    },
    /**紀錄選取status*/
    addStatusToLocation(status) {
      history.pushState(
        {},
        null,
        this.$route.path + '?status=' + encodeURIComponent(status)
      );
    },
  },
};
</script>
