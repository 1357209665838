<template>
  <v-card flat>
    <v-tabs v-model="tab" grow>
      <v-tab
        v-for="s in status"
        :key="s.id"
        @click="tabClick(s.id)"
        :disabled="disabled"
      >
        <v-badge
          color="accent"
          inline
          :value="s.modelsCount !== undefined ? s.modelsCount : 0"
          :content="s.modelsCount !== undefined ? s.modelsCount : 0"
        >
          {{ s.name }}
        </v-badge>
      </v-tab>
    </v-tabs></v-card
  >
</template>

<script>
export default {
  props: {
    statusData: {
      type: Object,
    },
    statusFromRoute: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      disabled: false,
      tab: 0,
      status: [
        { name: '待提交', id: 0, isCountable: true, modelsCount: 0 },
        { name: '待處理', id: 6, isCountable: true, modelsCount: 0 },
        { name: '處理中', id: 1, isCountable: true, modelsCount: 0 },
        { name: '已暫停', id: 2, isCountable: false, modelsCount: 0 },
        { name: '處理失敗', id: 3, isCountable: true, modelsCount: 0 },
        { name: '作廢', id: 4, isCountable: false, modelsCount: 0 },
        { name: '已完成', id: 5, isCountable: false, modelsCount: 0 },
      ],
    };
  },
  watch: {
    statusData: {
      handler() {
        this.status[0].modelsCount = this.statusData['Pending'];
        this.status[1].modelsCount = this.statusData['Queuing'];
        this.status[2].modelsCount = this.statusData['Processing'];
        this.status[3].modelsCount = this.statusData['Paused'];
        this.status[4].modelsCount = this.statusData['Failed'];
      },
      deep: true,
    },
    loading: {
      handler(val) {
        if (val) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.statusFromRoute !== undefined) {
      if (this.statusFromRoute == '0') {
        this.tab = 0;
      } else if (this.statusFromRoute == '6') {
        this.tab = 1;
      } else {
        this.tab = parseInt(this.statusFromRoute) + 1;
      }
    }
  },
  methods: {
    tabClick(status) {
      this.$emit('click', status);
    },
  },
};
</script>

<style></style>
